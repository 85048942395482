<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    >
      <base-img
        :src="require('@/assets/logo.svg')"
        class="mr-3 hidden-md-and-down pointer"
        contain
        max-width="70"
        width="100%"
        @click="goToHome"
      />

      <div
        contain
        class="pointer font-weight-bold font-medium color-primary"
        @click="goToHome"
      >
        CHEMOROZRUCH®
      </div>

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-md-and-down"
          optional
        >
          <v-tab
            v-for="item in items"
            :key="item.name"
            :to="item.path"
            :exact="item.name === $router.history.current.name"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold font-medium-small"
            min-width="6"
            text
          >
            {{ item.displayName }}
          </v-tab>
          <v-tab @click="goToLinkedin">
            <v-icon :size="30">
              mdi-linkedin
            </v-icon>
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  import { unslugify } from 'unslugify'
  import { PL, EN } from '@/constants/lang.js'
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_ALL } from '@/App.vue'

  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },
    data: () => ({
      drawer: null,
    }),
    computed: {
      ...mapState(STORE_NAMESPACE_ALL, [
        'translations',
      ]),
      items () {
        const routesByLang = this.$router.options.routes[0].children.filter(route => route.meta.lang === this.$route.meta.lang)
        const routes = routesByLang.filter(route => route.name !== 'FourOhFour' && route.name !== 'Home-pl' && route.name !== 'Home-en')
        const routeItems = []
        routes.forEach(route => {
          routeItems.push({
            name: route.name,
            path: route.path,
            displayName: this.unslugifyName(route.path),
          })
        })

        return routeItems
      },
    },
    methods: {
      unslugifyName (name) {
        return unslugify(name)
      },
      goToHome () {
        const name = this.$route.meta.lang === PL ? 'Home-pl' : 'Home-en'
        if (this.$route.name !== name) {
          this.$router.push({ name })
        }
      },
      goToLinkedin () {
        window.open(this.translations.linkedin, '_blank').focus()
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    z-index: 1500
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
